import React from "react";
import Layout from "../components/shared/layout";
import PaymentStatus from "../components/shared/payment-status";
import deviceStore from "../store/deviceStore";

const OrderSuccessPage = () => {
  return (
    <Layout header={true} footer={true} isBackBtn={true}>
      <PaymentStatus message1={deviceStore.deviceImei === "NA" ? "payment.success.deferred.message1" : "payment.success.message1"} message2={deviceStore.deviceImei === "NA" ? "" : "payment.success.message2"} />
    </Layout>
  )
}

export default OrderSuccessPage
